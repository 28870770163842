import * as React from "react"
import ScrollAnimation from 'react-animate-on-scroll';

const Features = ({ features, imgClass }) => {
    let delay = 200
    return (
        <div className="
            flex flex-col md:flex-row 
            justify-evenly 
            text-dark 
            my-32 lg:mt-36 lg:mb-24
            gap-y-10"
        >
            {features.map((item, index) => {
                if (index > 0) {
                    delay += 200
                }
                return (
                <div key={index} 
                className="
                    flex flex-initial flex-col
                    text-center
                    items-center
                    gap-y-4 
                    md:w-1/3"
                > 
                    <ScrollAnimation animateIn="fadeIn" delay={delay} animateOnce>
                    <img
                        src={item?.image?.publicURL}
                        alt=""
                        className={imgClass}
                    />
                    </ScrollAnimation>
                    <h3 className="text-xl sm:text-4xl font-medium mb-6">
                    {item?.heading}
                        </h3>
                    <p className="text-base sm:text-2xl">
                        {item?.text}
                    </p>
                    </div>
                
             )})}
        </div>
    )
}

export default Features