import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Faq = ({ faq }) => {
    return (
        <div className="flex flex-row items-center justify-center my-32 lg:my-44 lg:mb-20">
            <div className="
                hidden 2xl:flex 
                flex-[0_0_167px]
            ">
                <StaticImage src="../../images/about-page-faq-left.png" alt=""/>
            </div>
            <div className="
                flex flex-col
                max-w-[955px]
                px-6 sm:px-20 py-24 sm:py-36
                bg-white
                rounded-lg"
            >
                    <h2 className="text-2xl sm:text-5xl font-medium">
                        Frequently Asked Questions (FAQs):
                    </h2>
                <ul className="list-none mt-16">
                    {faq.map((item, index) => (
                        <li 
                            key={index} 
                            className="mb-10"
                        >
                            <h3 className="text-xl sm:text-4xl font-medium">{item.question}</h3>
                            <p className="text-base sm:text-xl">{item.answer}</p>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="
                hidden 2xl:flex 
                flex-[0_0_165px]
            ">
                <StaticImage src="../../images/about-page-faq-right.png" alt=""/>
            </div>
        </div>
    )
}

export default Faq