import * as React from "react"
import { graphql } from "gatsby"

// Custom components
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Story from "../components/story/story"
import Brand from "../components/about/brand"
import Features from "../components/features/features"
import Faq from "../components/about/faq"

// Static files
import Line from "../images/line-about.svg"

const LineData = (
  [Line, '-top-2', 'right-0', 'scale-y-104', '']
)

export const query = graphql`
query {
  allMdx(filter: {frontmatter: {templateKey: {eq: "about"}}}) {
    nodes {
      frontmatter {
        metaDescription
        metaTitle
        templateKey
        intro {
          heading
          text
          imageAlt
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        tagline {
          firstline
          secondline
        }
        features {
          mission {
            heading
            text
            image {
              publicURL
            }
          }
          vision {
            heading
            text
            image {
              publicURL
            }
          }
        }
        faq {
          question
          answer
        }
      }
    }
  }
}
` 
const IndexPage = ({ data }) => {
  const allData = data?.allMdx?.nodes[0]
  const allIntro = allData?.frontmatter?.intro 
  const allFeatures = Object.values(allData?.frontmatter?.features);
  const Stories = () => (
    allIntro.map((item, index) => {
      return (
          <Story
            key={index}
            heading={item.heading}
            text={item.text}
            image={item.image}
            imageAlt={item.imageAlt}
            reverse={index % 2 ? false : true}
          />
      )
    })
  )
  return (
    <Layout line={LineData}>
      <Seo 
        title={allData.frontmatter.metaTitle}
        description={allData?.frontmatter?.metaDescription}
      />
        <div className="flex flex-col gap-y-32 my-16">
          <Stories/>
        </div>
        <Brand 
          tagline={allData?.frontmatter?.tagline}
          mission={allData?.frontmatter?.mission}
          vision={allData?.frontmatter?.vision}
        />
        <Features
          features={allFeatures}
          imgClass="w-[104px] h-[153px]"
          />
        <Faq 
          faq={allData.frontmatter?.faq}
        />
    </Layout>
  )
}

export default IndexPage
