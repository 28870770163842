import * as React from "react"

const Brand = ({ tagline }) => {
    return (
        <div className="
            flex flex-row 
            justify-between
            w-full 
            h-[62px] sm:h-[165.98px] lg:h-[265.98px]
            bg-blue-darker 
            rounded-full"
        > 
                <h3 className="
                    flex flex-col 
                    gap-y-0 sm:gap-y-2 lg:gap-y-4
                    justify-center items-center 
                    w-full
                    font-medium
                    text-white 
                    text-base sm:text-xl lg:text-4xl"
                >
                    "{tagline?.firstline}<br/>
                    <span className="pl-0 sm:pl-28 lg:pl-40">
                    {tagline?.secondline}"</span>
                </h3>
        
            <div className="
                flex-shrink-0
                w-[62px] sm:w-[165.98px] lg:w-[265.98px] 
                h-[62px] sm:h-[165.98px] lg:h-[265.98px]  
                rounded-full
                bg-yellow"
            />
        </div>
    )
}

export default Brand